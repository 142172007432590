import React from "react";

import { Divider, FlexProps, HStack, Text } from "@chakra-ui/react";

export const DividerWithText = (props: FlexProps) => {
  const { color, children } = props;
  return (
    <HStack color={color}/*"gray.500"*/ align="stretch" alignItems="center">
      <Divider borderColor="currentColor" />
      <Text as="span" px="3" color="inherit" fontWeight="medium">
        {children}
      </Text>
      <Divider borderColor="currentColor" />
    </HStack>
  );
};
