import React from "react";

import { $ok } from "foundation-ts/commons";
import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { config } from "../config/config";
import { AuthIcon, CloudIcon } from "./icons";

export interface Provider {
    slug: ServiceSupplier;
    label: string;
    icon: JSX.Element;
}

export const authProviders: Array<Provider> = $ok(config.chambersign.clientId) ? 
    [
        {
            slug: ServiceSupplier.Chambersign,
            label: "Utiliser l'identité numérique",
            icon: <AuthIcon.Chambersign fill="#be1f24" size="2em" />,
        },
    ]
  : [
        {
            slug: ServiceSupplier.Google,
            label: "Google",
            icon: <AuthIcon.Google />,
        },
        {
            slug: ServiceSupplier.Chambersign,
            label: "Chambersign",
            icon: <AuthIcon.Chambersign fill="#be1f24" />,
        },
        // {
        //     slug: ServiceSupplier.Microsoft,
        //     label: "Microsoft",
        //     icon: <AuthIcon.Microsoft />,
        // }
    ] ;

export const cloudProviders: Array<Provider> = [
    {
        slug: ServiceSupplier.DropBox,
        label: "Dropbox",
        icon: <CloudIcon.DropBox />,
    },
    {
        slug: ServiceSupplier.Google,
        label: "Google Drive",
        icon: <CloudIcon.GoogleDrive />,
    },
    // {
    //     slug: ServiceSupplier.Microsoft,
    //     label: "Microsoft One Drive",
    //     icon: <CloudIcon.OneDrive />,
    // }
];

export type ProviderDictionary = { [key in ServiceSupplier]?: Provider };

export const authProvidersDict: ProviderDictionary = $ok(config.chambersign.clientId) ? 
    {
        'chambersign': authProviders[0],
    }
  : {
        'google': authProviders[0],
        'chambersign': authProviders[1],
        // 'microsoft': authProviders[2]
    } ;

export const cloudProvidersDict: ProviderDictionary = {
    'dropbox': cloudProviders[0],
    'google': cloudProviders[1],
    // 'microsoft': cloudProviders[2]
}

export const getLabelForAuthProvider = (slug: ServiceSupplier): string => {
    const provider = authProvidersDict[slug] ;
    return $ok(provider) ? provider!.label : slug ;
};

export const getIconForAuthProvider = (slug: ServiceSupplier): JSX.Element => {
    const provider = authProvidersDict[slug] ;
    return $ok(provider?.icon) ? provider!.icon! : <AuthIcon.Unknown /> ;
}


export const getLabelForCloudProvider = (slug: ServiceSupplier): string => {
    const provider = cloudProvidersDict[slug] ;
    return $ok(provider) ? provider!.label : slug ;
};

export const getIconForCloudProvider = (slug: ServiceSupplier): JSX.Element => {
    const provider = cloudProvidersDict[slug] ;
    return $ok(provider) ? provider!.icon : <CloudIcon.Unknown /> ;
};
