import { $isuuid, $ok, $partial } from "foundation-ts/commons";
import { TSError } from "foundation-ts/tserrors";
import { Resp, Verb } from "foundation-ts/tsrequest";
import { Nullable, UUID } from "foundation-ts/types";

import { ContactType, ServiceSupplier, UserBodyCommon } from "g1-commons/lib/doxecureTypes";
import { ContactDto, CreateUserBody, InvoiceDto, UpdateUserBody, UserDto } from "g1-commons/lib/doxecureClientTypes";

import { getUserUuid } from "./authentication.service";
import apiClient, { assertResponse, ChangePasswordFormValues} from "./apiClient";

export const minimalUserDto = (u: UserDto):UserDto => {
    return { apid: u.apid, lastName: u.lastName, firstName: u.firstName, email: u.email };
}

export const getUserProfile = async (userID:UUID): Promise<UserDto> => {
    return apiClient.getUser(userID)
                    .then(([profile, status]) => assertResponse(profile, status)) ;
};

export const getAdminProfile = async (userID:UUID): Promise<UserDto> => {
    const channels = await getUserChannels(userID) ; // This route is admin protected, so if user is not authorized an error is thrown
    return await getUserProfile(userID) ;
}

export const createNewUser = async (user: UserDto): Promise<UserDto> => {
    if ($ok(user.apid)) { throw "createNewUser(): user.apid must not be set to create a new user" ; }
    const [body, count] = $partial<UserDto, CreateUserBody>(user, {
        properties:[
            'lastName', 'firstName', 'email', 'phoneNumber', 'preferences', 
            'organizationName', 'vatNumber', 'organizationAddress', 'billingAddress' /*,
            'password', 'oldPassword', 'verifyPassword' */
        ]
    }) ;
    
    if (!count) { throw "createNewUser(): empty user creation body" ; }

    return apiClient.createUser(body)
                    .then(([newUser, status]) => assertResponse(newUser, status)) ;
};

export const getInvoices = async(): Promise<InvoiceDto[]> => {
    const userID = getUserUuid('getInvoices()') ;
    return apiClient.userInvoicesList(userID!)
                    .then(([invoices, status]) => assertResponse(invoices, status)) ;
}

export const addUserContact = async(userID: UUID, contactInfos:UserBodyCommon):Promise<ContactDto> => {
    return apiClient.addUserContact(userID!, contactInfos)
                    .then(([contact, status]) => assertResponse(contact, status)) ;
} 

export const getContacts = async (sourceUserID:UUID, contactType?: ContactType): Promise<ContactDto[]> => {
    return apiClient.userContactsList(sourceUserID, contactType)
                    .then(([contacts, status]) => assertResponse(contacts, status)) ;
};

export const patchUser = async (user: UserDto): Promise<UserDto> => {
    if (!$isuuid(user.apid)) { throw "patchUser(): user.apid should be a valid UUID" ; }
    const [body, count] = $partial<UserDto, UpdateUserBody>(user, {
        properties:[
            'lastName', 'firstName', 'email', 'phoneNumber', 'preferences',
            'organizationName', 'vatNumber', 'organizationAddress', 'billingAddress'
        ]
    }) ;
    
    return count > 0 ? apiClient.updateUser(user.apid!, body)
                                .then(([updatedUser, status]) => assertResponse(updatedUser, status)) : 
                       user ;
};

export const changePassword = async (values: ChangePasswordFormValues):Promise<UUID> => {
    return apiClient.changePassword(ServiceSupplier.BCA, values).then(([ret, status]) => assertResponse(ret,status)) ;
};

export const createAuthentication = async (userId: UUID, password: string): Promise<boolean> => {
    return apiClient.createUserAuthentication(userId, { password: password }) ;
}

export const searchUsersFromParams = async (search: string, excludecu: boolean = false): Promise<Nullable<UUID[]>> => {
    const [ret, status] = await apiClient.request(`/users?search=${search}&excludecu=${excludecu}`) ;
    if (status === Resp.Unauthorized) {
        TSError.throw("Vous n'êtes pas autorisé à accéder à cette page") ;
    }
    return (status === Resp.OK) ? ret as UUID[] : null ;
}

export const getUserChannels = async (userId: string): Promise<Nullable<UUID[]>> => {
    const [ret, status] = await apiClient.request(`/user/${userId}/channels`) ;
    if (status === Resp.Unauthorized) {
        TSError.throw("Vous n'êtes pas autorisé à accéder à cette page") ;
    } else if (status === Resp.PreconditionRequired) {
        TSError.throw("Non autorisé - OTP non validé", status) ;
    }
    return (status == Resp.OK) ? ret as UUID[] : null ;
}

export const deleteAuthenticationChannel = async (userId: string): Promise<boolean> => {
    const [ret, status] = await apiClient.request(`/user/${userId}/authentication-channel`, Verb.Delete) ;
    return status == Resp.OK && $isuuid(ret) ;
}

// export const getChannel = async (channelId: string): Promise<Nullable<ChannelDto>> => {
//     const [ret, status] = await apiClient.request(`/channel/${channelId}`)
//     return (status === Resp.OK) ? ret as ChannelDto : null ;
// }

export const setAdminRole = async (userId: UUID, enable: boolean = true): Promise<boolean> => {
    return await apiClient.setAdminRole(userId, enable) ;
}

export const removeContact = async (userID: UUID, contactID: UUID): Promise<boolean> => {
    const [ret, status] = await apiClient.removeContact(userID, contactID) ;
    return (status === Resp.OK) && (ret === contactID) ;
}

export const changeEmail = async (userId: UUID, token: string): Promise<Nullable<UUID>> => {
    return await apiClient.changeEmail(userId, token)! ;
}