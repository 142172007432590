import { Box, Button, HStack, Spacer, Text, VisuallyHidden } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { $ok } from "foundation-ts/commons";
import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { config } from "../../config/config";
import { authProviders } from "../../utils/Provider";

export interface AuthProvidersBoxProps {
    onSelect: (provider: ServiceSupplier) => void ;
    mode: "horizontal" | "vertical" ;
    isMobile?: boolean ;
}

export const AuthProvidersBox = (props: AuthProvidersBoxProps) => {
    const {isMobile, onSelect} = props ;
    const [fontSize, setFontSize] = useState("lg") ;

    useEffect(() => {
        setFontSize(isMobile ? "md" : "lg") ;
    }, [isMobile]) ;

    return (
        <Box w="100%" mt="6" display="flex" justifyContent="space-around" margin="1em 0">
            {authProviders.map((provider) => (
                $ok(config.chambersign.clientId) ? 
                    <Button
                        key={provider.slug}
                        variant="outline"
                        padding="0 2px"
                        backgroundColor={$g1Color('connection.provider.bg')}
                        color={$g1Color('connection.provider.write')}
                        onClick={() => onSelect(provider.slug)}
                        _hover={{ bg: $g1Color('connection.provider.hover.bg'), color: $g1Color('connection.provider.hover.write') }}
                    >
                        <HStack justifyContent="space-around">
                            {provider.icon}
                            <Text marginLeft={2} marginRight={3} fontWeight="600" fontSize={fontSize}>{provider.label}</Text>
                        </HStack>
                    </Button>
                :
                    <Button
                        key={provider.slug}
                        variant="outline"
                        padding="unset"
                        onClick={() => onSelect(provider.slug)}
                        _hover={{ bg: $g1Color('connection.provider.hover.bg') }}
                    >
                        <VisuallyHidden>Se connecter avec {provider.label}</VisuallyHidden>
                        {provider.icon}
                    </Button>
            ))}
        </Box>
    ) ;
}