import { Box, Heading, Text, Stack, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Image, useToast } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import { $UUID, $isemail, $ok, $string } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { Nullable } from "foundation-ts/types";
import { SessionStatus } from "g1-commons/lib/doxecureModelTypes";
import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { $g1Color, $g1IconURL } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { AuthProvidersBox } from "../../components/AuthProvidersBox/AuthProvidersBox";
import { DividerWithText } from "../../components/Dividers/DividerWithText";
import { Link } from "../../components/Link/Link";
import { G1Button } from "../../components/G1Button";
import { G1Input } from "../../components/G1Input";
import { PasswordField } from "../../components/PasswordField/PasswordField";
import VersionDisplay from "../../components/VersionDisplay";
import { config } from "../../config/config";
import { useDevice } from "../../hooks/useDevice";
import { useLogin } from "../../hooks/useLogin";
import { authenticateWithProvider, getUserUuid, isLoggedIn, sendAdminOTP } from "../../services/authentication.service";
import { getOneSessionForUser } from "../../services/session.service";
import { getUserProfile } from "../../services/users.service";
import { optlog } from "../../utils/functions";
import { defaultErrorToast } from "../../utils/toast";

export interface LoginProps {
  adminPage: Nullable<boolean>
}

export const Login = ({ adminPage }: LoginProps) => {
    const { mutateAsync: performLogin, isLoading } = useLogin() ;
    const {
        register,
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    }) ;
 
    const [isSubmitting, setIsSubmitting] = useState(false) ;
    const [email, setEmail] = useState('') ;
    const [password, setPassword] = useState('') ;
    const { isMOBILE } = useDevice() ;
    const toast = useToast() ;

    const location = useLocation() ;
    const searchParams = new URLSearchParams(location.search) ;
    const error = searchParams.get("err") ;
    if ($ok(error)) {
        const str = (error === "401") ? "Un compte utilisateur existe déjà avec cet email. Veuillez utiliser ce compte au lieu de celui du fournisseur sélectionné." : `Impossible de se connecter au service du fournisseur sélectionné (Erreur: ${error})` ;
        toast(defaultErrorToast(str)) ;
        searchParams.delete("err") ;
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault() ;
        setIsSubmitting(true) ;
        optlog(`did enter values { username:'${email}', password:'${password}' }`) ;
        try {
            await performLogin({ username: email, password: password, admin: adminPage }) ;
            if ($ok(adminPage) && adminPage) {
                if (await sendAdminOTP(getUserUuid())) {
                    navigate("/admin/check-otp") ;
                } else {
                    toast(defaultErrorToast("Erreur lors de l'envoi du code à usage unique")) ;
                }
            } else {
                const localPathname = ($ok(localStorage.getItem("pathname")) ? localStorage.getItem("pathname")! : "app/signatures").replace(/^(https:\/)?\//, "") ;
                const localSearch = localStorage.getItem("search") ;
                if ($ok(localSearch)) {
                    const params = new URLSearchParams(localSearch!) ;
                    const sid = params.get("sid") ;
                    if (!$ok(sid)) {
                        navigate(`/${localPathname}`) ;
                        return ;
                    }
                
                    const session = await getOneSessionForUser($UUID(sid)) ;
                    if (!$ok(session)) {
                        navigate(`/${localPathname}`) ;
                        return ;
                    }
                    localStorage.clear() ;
                    navigate((session.status === SessionStatus.Signed) ? `/app/signedDocuments?sid=${sid}` : `/${localPathname}?sid=${sid}`) ;
                } else {
                    navigate(`${localPathname}`) ;
                }
            }
        } catch (e) {
            optlog(e);
            if (e.statusCode === 401) {
                toast(defaultErrorToast("Veuillez vérifier vos informations de connexion")) ;
                setPassword('');
            } else {
                toast(defaultErrorToast("Impossible de se connecter")) ;
            }
        }
        setIsSubmitting(false) ;
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const pwd = $string(e.currentTarget.value) ;
        optlog(`new pwd = ${pwd}`) ;
        setPassword(pwd) ;
    }

    const handleMailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const mail = $trim(e.currentTarget.value) ;
        optlog(`--------\nnew mail = ${mail} (${mail.length})`) ;
        setEmail(mail) ;
    }

    const handleSelectProvider = async (provider: ServiceSupplier) => {
        await authenticateWithProvider(provider) ;
    }

    useEffect(() => {
        if (!adminPage && isLoggedIn()) {
            const u = getUserProfile(getUserUuid()!) ;
            if ($ok(u)) {
                navigate("/app/signatures") ;
            }
        }
    }, [adminPage]) ;

    return (
        <Box py="5%">
            <Box color={$g1Color('connection.logo.write')} bg={$g1Color('connection.logo.bg')} paddingTop="8" paddingBottom="2" px="8" maxW="md" mx="auto" rounded={{ base: "0", md: "2xl" }}>
                <VStack spacing="4" align="stretch">
                    <Stack direction="row" alignItems="center" spacing="2">
                        <Image src={$g1IconURL('connection.logo.iconurl')} alt="Application logo" boxSize="72px" />
                        <Heading as="h1" textAlign="center" size="lg" fontWeight="bold">
                            {config.app.title}
                        </Heading>
                    </Stack>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        Connectez-vous
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="6" bg={$g1Color('connection.form.bg')} color={$g1Color('connection.form.write')} py="8" px={{ base: "4", md: "10" }} shadow="base" rounded="lg" paddingBottom="1em">
                            <FormControl>
                                <FormLabel fontWeight="light">Adresse mail</FormLabel>
                                <G1Input
                                    id="email" name="email"
                                    type="email"
                                    component="connection.form"
                                    placeholder="test@test.com"
                                    onChange={handleMailChange}
                                    onBlur={handleMailChange}
                                    onInput={handleMailChange}
                                    autoFocus
                                />
                            </FormControl>
                            <PasswordField
                                id="pwd" label="Mot de passe"
                                registerInput={{ ...register("password", { required: true }) }}
                                component="connection.form"
                                onChange={handlePasswordChange}
                            />
                            <Link
                                component="connection.form"
                                href="/reinitpwd"
                                style={{ fontSize: "0.9rem", textDecoration: "underline", marginTop: "5px", textAlign: "center" }}
                            >
                                Mot de passe oublié ?
                            </Link>
                            <G1Button component="connection" variant="ok" title="Se connecter" type="submit" size="lg" isLoading={ isSubmitting || isLoading } disabled={ isSubmitting || isLoading || password.length < config.passwordConstraints.minSize || !$isemail(email) } />
                            { !config.with_external_providers || adminPage ? '' :
                              (<>
                                <DividerWithText color={$g1Color('connection.form.write')}>ou</DividerWithText>
                                <AuthProvidersBox onSelect={handleSelectProvider} mode="horizontal" isMobile={isMOBILE} /> 
                               </>)
                            }
                        </Stack>
                    </form>
                    <VersionDisplay />
                </VStack>
            </Box>
        </Box>
    )
}
