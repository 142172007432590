import { useMutation } from "react-query";

import { Nullable } from "foundation-ts/types";

import { authenticate } from "../services/authentication.service";

export const useLogin = () => {
  return useMutation(({ username, password, admin }: { username: string; password: string, admin: Nullable<boolean> }) =>
    authenticate(username, password, admin)
  );
};
