import { chakra, HTMLChakraProps } from "@chakra-ui/system";
import React from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { Component } from "../../utils/TypesAndConstants";

interface LinkProps extends HTMLChakraProps<"a"> {
    component: Component ;
}

export const Link = (props: LinkProps) => {
    const { component, ...rest } = props ;

    return (
        <chakra.a
            marginStart="1"
            href="#"
            color={$g1Color(`${component}.link.write`)}
            _hover={{ color: $g1Color(`${component}.link.hover.write`) }}
            display={{ base: "block", sm: "inline" }}
            {...rest}
        />
    ) ;
} ;